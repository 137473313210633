import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NoResults, Button } from '@patient-access/ui-kit';
import { ReportsContent as ReportsContentWrapper } from 'components/Pages/Reports/wrappers';
import { getReports } from 'actions/reports';
import { isEmptyObject } from 'helpers/checkValues';
import { setActiveAdminMenu, setActiveReportsMenu } from 'actions/profile';
import locale from 'service/locale';
import './styles.scss';

import ReportsFilters from './ReportsFilters/ReportsFilters';
import ReportsContent from './ReportsContent/ReportsContent';
import DownloadOverlay from './ReportsOverlay/DownloadOverlay';
import * as RolesConstants from 'constants/RolesConstants';
import PendingAppointmentsNotification from 'components/Pages/Calendar/CalendarView/PendingAppointment/PendingAppointmentsNotification';
import { getPendingAppointments, setDisplayPendingAppointmentList } from "actions/branches";
import CalendarListView from 'components/Pages/Calendar/CalendarView/Calendar/CalendarListView/CalendarListView';
import AppointmentDetails from 'components/Share/AppointmentDetails/AppointmentDetails';
import * as AppointmentDetailsConstants from "constants/AppointmentDetailsConstants";
import Modal from 'components/Share/Modal/Modal';
import { PENDING_ALERT_PAGE } from 'constants/BranchesConstants';

import { ai } from "service/telemetry";

type Props = {
  revenue: any,
  appointment: any,
  getReports: () => Action,
  isLoading: boolean,
  isError: boolean,
  currentRole: any,
  profileOrganisation: Organisation,
  setActiveAdminMenu: (status: boolean) => Action,
  setActiveReportsMenu: (status: boolean) => Action,

  // Telemetry
  aiUserId : string,
  aiSessionId: string,
  aiRoleName: string,
  aiOrganizationId: string,
  aiBranchId: string,
  aiCareProviderId: string,
  isDisplayListView: boolean,
  isActiveAgendaDetails: boolean,
  getPendingAppointments: (pendingAlertPage: string) => Function,
  setDisplayPendingAppointmentList: (isDisplayPendingAppointmentList: boolean) => Function,
};

type State = {
  isResizing: boolean,
};

const mapStateToProps = (state) => ({
  revenue: state.reportsBasic.revenue,
  appointment: state.reportsBasic.appointment,
  isLoading: state.reportsBasic.isLoading,
  isError: state.reportsBasic.isError,
  currentRole: state.roles.profileCurrentRole,
  profileOrganisation: state.profile.organisation,

  // Telemetry
  aiUserId: state.profile.id,
  aiSessionId: state.profile.sessionId,
  aiRoleName: state.roles.profileCurrentRole.role,
  aiOrganizationId: state.profile.organisation.organisationId,
  aiBranchId: state.branchDetails.branchId,
  aiCareProviderId: state.branchDetails.careProviderId,
  isDisplayListView: state.branches.isDisplayPendingAppointmentList,
  isActiveAgendaDetails: state.panel.isActiveAgendaDetails
});

const mapDispatchToProps = (dispatch: (action: any) => Action): any => ({
  getReports: () => dispatch(getReports()),
  setActiveAdminMenu: (status) => dispatch(setActiveAdminMenu(status)),
  setActiveReportsMenu: (status) => dispatch(setActiveReportsMenu(status)),
  getPendingAppointments: (pendingAlertPage: string) => dispatch(getPendingAppointments(pendingAlertPage)),
  setDisplayPendingAppointmentList: (isDisplayPendingAppointmentList: boolean) => dispatch(setDisplayPendingAppointmentList(isDisplayPendingAppointmentList))
});

class Basic extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      isResizing: false,
    };
  }

  componentDidMount = () => {
    window.addEventListener('resize', this.setIsResizing);
    const { setActiveAdminMenu, setActiveReportsMenu, aiOrganizationId, aiBranchId, aiCareProviderId, aiRoleName, aiUserId, aiSessionId } = this.props;
    setActiveAdminMenu(false);
    setActiveReportsMenu(true);
    // Telemetry
    ai.appInsights.trackEvent({
      name: 'PAProReports',
    }, {
      UserId: aiUserId,
      SessionId: aiSessionId,
      RoleName: aiRoleName,
      OrganizationId: aiOrganizationId || locale.Telemetry.naOrganisationId,
      BranchId: aiBranchId || locale.Telemetry.naBranchId,
      CareProviderId: aiCareProviderId || locale.Telemetry.naCareProviderId,
    });
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.setIsResizing);
    if (this.resizeTimer) clearTimeout(this.resizeTimer);
  }

  setIsResizing = () => {
    this.setState({ isResizing: true });
    if (this.resizeTimer) clearTimeout(this.resizeTimer);
    this.resizeTimer = setTimeout(() => {
      this.setState({ isResizing: false });
    }, 1000);
  }

  handlerTryAgainButton = (e: any) => {
    e.preventDefault();
    const { getReports, getPendingAppointments } = this.props;
    getPendingAppointments(PENDING_ALERT_PAGE.REPORT);
    getReports();
  }

  handleReportFilterChange = () => {
    const { getReports, getPendingAppointments } = this.props;
    getPendingAppointments(PENDING_ALERT_PAGE.REPORT);
    getReports();
  }

  render() {
    const { revenue, appointment, isLoading, isError, currentRole, profileOrganisation, isDisplayListView, isActiveAgendaDetails } = this.props;
    const { isResizing } = this.state;
    const isEmptyRevenue = isEmptyObject(revenue);
    const isEmptyAppointment = isEmptyObject(appointment);

    const userOrganisationId = currentRole.role !== RolesConstants.ADMIN ? currentRole.organizationId : null;

    const hasSingleBranch = (currentRole.role === RolesConstants.ORGANIZATION_ADMIN) && (profileOrganisation.branches && profileOrganisation.branches.length === 1);
    const userBranchId = hasSingleBranch
      ? profileOrganisation.branches[0].id
      : [RolesConstants.BRANCH_MEMBER, RolesConstants.BRANCH_ADMIN].includes(currentRole.role)
        ? currentRole.branchId
        : null;

    if (isError) {
      return (
        <ReportsContentWrapper>
          <div className="reports-basic">
            <NoResults type="info">
              <h2>{locale.Reports.errorHeader}</h2>
              <Button
                buttonType="secondary"
                messageKey="errorButton"
                defaultMessage={locale.Reports.errorButton}
                onClick={this.handlerTryAgainButton}
                isLoading={isLoading}
              />
            </NoResults>
          </div>
        </ReportsContentWrapper>
      );
    }
    return (
      <ReportsContentWrapper>
        {isDisplayListView && (
          <CalendarListView pendingAlertPage={PENDING_ALERT_PAGE.REPORT} />
        )}
        {isActiveAgendaDetails && (
          <Modal>
            <AppointmentDetails
              type={AppointmentDetailsConstants.APPOINTMENT_DETAILS_TYPES.AGENDA}
            />
          </Modal>
        )}
        {!isDisplayListView && (
          <div className="reports-basic">
            <ReportsFilters
              isLoading={isLoading}
              handleReportFilterChange={this.handleReportFilterChange}
              userOrganisationId={userOrganisationId}
              userBranchId={userBranchId}
            />
            <PendingAppointmentsNotification
              pendingAlertPage={PENDING_ALERT_PAGE.REPORT}
            />
            <ReportsContent
              revenue={revenue}
              appointment={appointment}
              isResizing={isResizing}
              isLoading={isLoading}
              isEmptyRevenue={isEmptyRevenue}
              isEmptyAppointment={isEmptyAppointment}
            />
            <DownloadOverlay />
          </div>
        )}
      </ReportsContentWrapper>
    );
    
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Basic);
