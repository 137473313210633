import React, { Component } from "react";
import BigCalendar from "react-big-calendar";
import { connect } from "react-redux";
import moment from "moment";

import { Loader, Icon, IconVideo, IconPhone } from "@patient-access/ui-kit";
import "moment/locale/en-gb";
import "react-big-calendar/lib/css/react-big-calendar.css";

import { getBranchCalendarsAppointments, setDisplayPendingAppointmentList } from "actions/branches";
import { getAgendaAppointments } from "actions/agenda";
import { getAppointmentDetails, getInternalEventDetails } from "actions/appointmentDetails";
import { setPeriod, setDate, showSelectedSlot } from "actions/calendarView";
import AppointmentDetails from "components/Share/AppointmentDetails/AppointmentDetails";
import type { Action } from "types/actions";
import type { SlotCalendar } from "types/appointments";
import { getSlots, openBookingForm } from "actions/bookAppointment";
import { getNextDate } from "helpers/common";
import Modal from "components/Share/Modal/Modal";
import type { OpenHours, BookingCutoff, ClosedDay } from "types/calendars";
import * as AppointmentDetailsConstants from "constants/AppointmentDetailsConstants";
import type { AppointmentDetailsType } from "constants/AppointmentDetailsConstants";
import type { CalendarView } from "constants/AppointmentConstants";
import * as AppointmentConstants from "constants/AppointmentConstants";
import { MINUTES_INTERVAL_TO_EVENT_SUMMARY_TYPE } from "constants/CalendarsConstants";
import * as ServiceConstants from "constants/ServicesConstants";
import CustomToolbar from "./CalendarToolbar/CalendarToolbar";
import CustomView from "./CustomView/CustomView";
import CalendarListView from "./CalendarListView/CalendarListView";
import CustomHeader from "./CustomHeader/CustomHeader";
import locale from "service/locale";
import InternalEventDetails from "../InternalEventDetails/InternalEventDetails";
import * as RolesConstants from "constants/RolesConstants";
import { ai } from "service/telemetry";
import "./styles.scss";
import { PENDING_ALERT_PAGE } from "constants/BranchesConstants";

moment.locale("en-gb");
const localizer = BigCalendar.momentLocalizer(moment);

type Props = {
  getBranchCalendarsAppointments: () => Function,
  showSelectedSlot: (zIndex: number | null) => Action,
  getAgendaAppointments: () => Function,
  getAppointmentDetails: (
    appointmentId: string,
    type: AppointmentDetailsType
  ) => Function,
  openBookingForm: (
    startTime: string,
    endTime: string,
    defaultCalendar: SlotCalendar,
    slotCalendars: SlotCalendar[]
  ) => Action,
  calendars: any[],
  isActiveAgendaDetails: boolean,
  events: any[],
  resourceMap: any[],
  currentView: CalendarView,
  currentDate: any,
  branchId: string,
  setPeriod: (period: string) => Action,
  setDate: (date: Date) => Action,
  isOpenSlots: boolean,
  getSlots: (branchId: string) => Function,
  events: any[],
  openHours: OpenHours[],
  bookingCutoff: BookingCutoff,
  closedDays: ClosedDay[],
  isPendingAppointments: boolean,
  searchEvents: string[],
  isActiveSearchResults: boolean,
  selectedSlot: number | null,
  blockedDays: string[],
  searchResults: string[],
  getInternalEventDetails: (
    calendarId: string,
    eventId: string
  ) => Function,
  isActiveEventDetails: string,
  branchDetails: any,

  // Telemetry
  aiUserId: string,
  aiSessionId: string,
  aiRoleName: string,
  aiOrganizationId: string,
  aiBranchId: string,
  aiCareProviderId: string,
  isDisplayListView: boolean,
  setDisplayPendingAppointmentList: (isDisplayPendingAppointmentList: boolean) => Function,
};

type State = {
  filteredEvents: any[]
};

const mapStateToProps = state => ({
  calendars: state.branchDetails.calendarsList,
  isActiveAgendaDetails: state.panel.isActiveAgendaDetails,
  resourceMap: state.calendarView.resourceMap,
  branchId: state.router.branchId,
  currentView: state.calendarView.currentView,
  currentDate: state.calendarView.currentDate,
  events: state.calendarView.events,
  isOpenSlots: state.panel.isActiveAppointmentSlots,
  openHours: state.calendar.openHours,
  closedDays: state.calendar.closedDays,
  bookingCutoff: state.calendar.bookingCutoff,
  isPendingAppointments: state.branchDetails.getAppointmentsPending,
  isActiveSearchResults: state.panel.isActiveSearchResults,
  isActiveEventDetails: state.panel.isActiveInternalEventDetails,
  searchEvents: state.search.events,
  selectedSlot: state.calendarView.selectedSlot,
  blockedDays: state.calendar.blockedDays,
  searchResults: state.search.events,
  branchDetails: state.branchDetails,

  // Telemetry
  aiUserId: state.profile.id,
  aiSessionId: state.profile.sessionId,
  aiRoleName: state.roles.profileCurrentRole.role,
  aiOrganizationId: state.profile.organisation.organisationId,
  aiBranchId: state.branchDetails.branchId,
  aiCareProviderId: state.branchDetails.careProviderId,
  isDisplayListView: state.branches.isDisplayPendingAppointmentList
});

const mapDispatchToProps = (dispatch: (action: any) => Function): Object => ({
  getBranchCalendarsAppointments: () => dispatch(getBranchCalendarsAppointments()),
  getAgendaAppointments: () => dispatch(getAgendaAppointments()),
  getAppointmentDetails: (appointmentId, type) => dispatch(getAppointmentDetails(appointmentId, type)),
  setPeriod: period => dispatch(setPeriod(period)),
  setDate: date => dispatch(setDate(date)),
  showSelectedSlot: zIndex => dispatch(showSelectedSlot(zIndex)),
  getSlots: branchId => dispatch(getSlots(branchId)),
  openBookingForm: (startTime, endTime, defaultCalendar, slotCalendars) => dispatch(openBookingForm(startTime, endTime, defaultCalendar, slotCalendars)),
  getInternalEventDetails: (calendarId, eventId) => dispatch(getInternalEventDetails(calendarId, eventId)),
  setDisplayPendingAppointmentList: (isDisplayPendingAppointmentList: boolean) => dispatch(setDisplayPendingAppointmentList(isDisplayPendingAppointmentList))
});

class Calendar extends Component<Props, State> {
  state = {
    filteredEvents: this.props.events
  };

  componentDidMount = () => {
    const {
      getBranchCalendarsAppointments,
      getAgendaAppointments,
      aiUserId,
      aiSessionId,
      aiRoleName,
      aiOrganizationId,
      aiBranchId,
      aiCareProviderId,
      setDisplayPendingAppointmentList
    } = this.props;
    getBranchCalendarsAppointments();
    setDisplayPendingAppointmentList(false);
    getAgendaAppointments();

    // Telemetry
    const isBranchAdmin = aiRoleName === RolesConstants.BRANCH_ADMIN;
    const isBranchMember = aiRoleName === RolesConstants.BRANCH_MEMBER;
    if (isBranchAdmin || isBranchMember) {
      ai.appInsights.trackEvent({
        name: 'PAProAdmin',
      }, {
        UserId: aiUserId,
        SessionId: aiSessionId,
        RoleName: aiRoleName,
        OrganizationId: aiOrganizationId || locale.Telemetry.naOrganisationId,
        BranchId: aiBranchId || locale.Telemetry.naBranchId,
        CareProviderId: aiCareProviderId || locale.Telemetry.naCareProviderId,
      });
    }
  };

  componentWillReceiveProps = (nextProps: Props) => {
    if (this.props.events !== nextProps.events) {
      this.setState({
        filteredEvents: nextProps.events.filter(event =>
            !event.isHiddenCancellation &&
            !event.isHiddenCalendar &&
            !event.isHiddenService &&
            !event.isHiddenStatus
        )
      });
    }
  };

  shouldComponentUpdate = (nextProps: Props) => {
    if (nextProps.isPendingAppointments && this.props.isPendingAppointments) return false;
    return true;
  };
  
  onSelectEvent = (e: any) => {
    const { currentView, isOpenSlots, getInternalEventDetails, branchDetails, aiUserId, aiRoleName, aiSessionId } = this.props;
    const { start, appointmentId, endTime, startTime } = e;

    // Telemetry
    ai.appInsights.trackEvent({
      name: 'PAProCalendar',
    }, {
      UserId: aiUserId,
      SessionId: aiSessionId,
      RoleName: aiRoleName,
      OrganizationId: branchDetails.organisationId,
      BranchId: branchDetails.branchId,
      CareProviderId: branchDetails.careProviderId,
      AppointmentId: appointmentId,
      AppointmentStartTime: startTime,
      AppointmentEndTime: endTime,
    });

    if (isOpenSlots && (currentView === AppointmentConstants.CALENDAR_VIEWS.MONTH)) {
      const { setDate, setPeriod } = this.props;
      setDate(start);
      setPeriod(AppointmentConstants.CALENDAR_VIEWS.DAY);
    } else {
      if (!isOpenSlots) {
        const { appointmentId, eventId, service } = e;
        const { getAppointmentDetails, isActiveSearchResults, searchResults } = this.props;
        if (!(isActiveSearchResults && searchResults.indexOf(appointmentId) === -1) && service.id !== ServiceConstants.INTERNAL_EVENTS_AS_SERVICE_ID) {
          getAppointmentDetails(appointmentId, AppointmentDetailsConstants.APPOINTMENT_DETAILS_TYPES.AGENDA);
        }
        else if (service.id === ServiceConstants.INTERNAL_EVENTS_AS_SERVICE_ID) {
        if (!!eventId) {
        const { calendar  } = e;
          getInternalEventDetails(calendar.id, eventId);
        }
        else
          getAppointmentDetails(appointmentId);
        }
      } else {
        const { openBookingForm } = this.props;
        const start = moment(e.start).format().split("+")[0];
        const end = moment(e.end).format().split("+")[0];
        openBookingForm(
          start,
          end,
          e.calendars[0],
          e.calendars
        );
      }
    }
  };

  getCalendarEvents = () => {
    const { isOpenSlots, branchId, getSlots, getBranchCalendarsAppointments } = this.props;
    isOpenSlots ? getSlots(branchId) : getBranchCalendarsAppointments();
  };

  handleChangeView = (view: CalendarView) => {
    const { setPeriod } = this.props;
    setPeriod(view);
    this.getCalendarEvents();
  };

  handleNavigate = (date: Date) => {
    const { setDate } = this.props;
    setDate(moment(date).toDate());
    this.getCalendarEvents();
  };

  eventStyleGetter = (event, start, end) => {
    const { searchEvents, isActiveSearchResults, isOpenSlots, selectedSlot, currentView } = this.props;
    const { id, zIndex, calendar } = event;
    const isCalendarEventsMonthView = !isOpenSlots && currentView !== AppointmentConstants.CALENDAR_VIEWS.MONTH;
    const isSlotSelected = selectedSlot === zIndex;
    let zIndexOfEvent = "1000";
    if (isOpenSlots) {
      zIndexOfEvent = isSlotSelected ? "9999" : zIndex;
    }
    const faded = isActiveSearchResults && searchEvents.findIndex(searchResult => searchResult === id) === -1;
    const isEventCancelled = event.status === locale.Appointment.status.cancelled.status;
    const eventSummaryType = this.getEventSummaryType(start, end);
    const className = [
      'patient-care-calendar-event',
      !isCalendarEventsMonthView ? "overridden" : "",
      isSlotSelected ? "selected" : "",
      isOpenSlots ? "-available-time-slot" : "",
      isEventCancelled ? "cancelled" : "",
      faded ? "faded" : "",
      eventSummaryType,
      isOpenSlots ? "" : `calendar-color-${calendar ? calendar.color : 1}`
    ].join(" ");

    return {
      title: "title",
      className,
      style: {
        zIndex: zIndexOfEvent
      }
    };
  };

  getEventSummaryType = (start: Date, end: Date) => {
    const startMoment = moment(start);
    const endMoment = moment(end);
    const diff = endMoment.diff(startMoment, "minutes");
    const { type } = MINUTES_INTERVAL_TO_EVENT_SUMMARY_TYPE.find(m => {
      return (diff >= m.interval[0]) && (diff <= m.interval[1])
    });

    return type;
  };

  slotPropGetter = (slotTime: Date, resourceId: string) => {
    let className = "";
    const slotDayOfWeek = moment(slotTime).day();
    const { openHours, closedDays, bookingCutoff, isOpenSlots, currentView, blockedDays, resourceMap } = this.props;
    const { filteredEvents } = this.state;
    const isOverLastBookingDay = moment().add(bookingCutoff.advance.value, "months").endOf("day") < moment.utc(slotTime);
    const slotTimeEnd = moment(slotTime).add(5, "minutes");
    const slotDate = moment(slotTime).format().slice(0, 10);
    const slotDateOpenHours = openHours.find(day => day.day === slotDayOfWeek);
    const isClosedDay = closedDays.find(day => day.date.split("T")[0] === slotDate);
    const isBlockedDay = blockedDays.find(day => day === slotDate);
    const { timesSessions } = slotDateOpenHours;
    const slotTimeHours = moment(slotTime).hours();
    const slotTimeMinutes = moment(slotTime).minutes();

    const matchedTimeSession = timesSessions.find(
      session => {
        const sessionHoursFrom = (session.from || "").slice(0, 2);
        if (slotTimeHours < sessionHoursFrom) return false;
        const sessionMinutesFrom = (session.from || "").slice(3, 5);
        if (slotTimeHours <= sessionHoursFrom && slotTimeMinutes < sessionMinutesFrom) return false;
        const sessionHoursTo = (session.to || "").slice(0, 2);
        if (slotTimeHours > sessionHoursTo) return false;
        const sessionMinutesTo = (session.to || "").slice(3, 5);
        if (slotTimeHours === sessionHoursTo && slotTimeMinutes >= sessionMinutesTo) return false;
        const sessionFrom = moment(slotDate).hours(sessionHoursFrom).minutes(sessionMinutesFrom);
        const sessionTo = moment(slotDate).hours(sessionHoursTo).minutes(sessionMinutesTo);
        return moment(slotTime).isSameOrAfter(sessionFrom, "minute") && moment(slotTimeEnd).isSameOrBefore(sessionTo, "minute");
      }
    );

    let slotCalendar = resourceId && resourceMap && resourceMap.find(calendar => calendar.resourceId === resourceId);
    if (resourceMap && resourceMap.length === 1) slotCalendar = resourceMap[0];

    const slotCalendarAvailablePeriod = slotCalendar && (slotCalendar.resourceOpenTimes || []).find(period => period.dayOfWeek === slotDayOfWeek);
    const calendarAvailablePeriodStartHour = slotCalendarAvailablePeriod && parseInt((slotCalendarAvailablePeriod.startTime || "").slice(0, 2), 10);
    const calendarAvailablePeriodStartMinute = slotCalendarAvailablePeriod && parseInt((slotCalendarAvailablePeriod.startTime || "").slice(3, 5), 10);
    const calendarAvailablePeriodEndHour = slotCalendarAvailablePeriod && parseInt((slotCalendarAvailablePeriod.endTime || "").slice(0, 2), 10);
    const calendarAvailablePeriodEndMinute = slotCalendarAvailablePeriod && parseInt((slotCalendarAvailablePeriod.endTime || "").slice(3, 5), 10);
    const calendarBlockedPeriods = slotCalendar && (slotCalendar.resourceBlockedPeriods || []).filter(period => moment(slotDate).isBetween(period.startDate, period.endDate, null, "[]"));
    const slotCalendarBlockedPeriod = (calendarBlockedPeriods || []).find(period => {
      if (!period.startTime || !period.endTime) return false;
      const periodHoursFrom = period.startTime.slice(0, 2);
      const periodHoursTo = period.endTime.slice(0, 2);
      const periodMinutesFrom = period.startTime.slice(3, 5);
      const periodMinutesTo = period.endTime.slice(3, 5);
      const periodFrom = moment(slotDate).hours(periodHoursFrom).minutes(periodMinutesFrom);
      const periodTo = moment(slotDate).hours(periodHoursTo).minutes(periodMinutesTo);
      return moment(slotTime).isSameOrAfter(periodFrom, "minute") && moment(slotTimeEnd).isSameOrBefore(periodTo, "minute");
    });

    if (matchedTimeSession) {
      className = "";
    } else if (currentView !== AppointmentConstants.CALENDAR_VIEWS.MONTH) {
      className = "blocked";
    }

    if (isOverLastBookingDay && timesSessions.length && !isClosedDay) {
      className = "cutoff";
    }

    if (
      (currentView === AppointmentConstants.CALENDAR_VIEWS.DAY || (resourceMap && resourceMap.length === 1)) &&
      matchedTimeSession &&
      (
        !slotCalendarAvailablePeriod ||
        slotTimeHours < calendarAvailablePeriodStartHour ||
        (slotTimeHours >= calendarAvailablePeriodStartHour && slotTimeMinutes < calendarAvailablePeriodStartMinute) ||
        slotTimeHours > calendarAvailablePeriodEndHour ||
        (slotTimeHours === calendarAvailablePeriodEndHour && slotTimeMinutes >= calendarAvailablePeriodEndMinute)
      )
    ) {
      className = "unavailable";
    }

    if (
      currentView !== AppointmentConstants.CALENDAR_VIEWS.MONTH &&
      ((!isOpenSlots && (isBlockedDay || isClosedDay)) ||
      ((calendarBlockedPeriods || []).find(period => period.startTime === null)) ||
      slotCalendarBlockedPeriod)
    ) {
      className = "blocked";
    }

    if (timesSessions.length === 0 && currentView === AppointmentConstants.CALENDAR_VIEWS.MONTH) {
      className = "blocked";
    }

    const isHavingSlotsInMonthView =
      isOpenSlots &&
      currentView === AppointmentConstants.CALENDAR_VIEWS.MONTH &&
      filteredEvents.find(event => moment(event.start).format().slice(0, 10) === slotDate);

    return {
      className,
      style: {
        backgroundColor: isHavingSlotsInMonthView ? "white" : null
      }
    };
  };

  customEvent = eventData => {
    const { currentView, isOpenSlots } = this.props;
    const { title, event } = eventData;
    const isEventCancelled = event.status === locale.Appointment.status.cancelled.status;
    const isInternalEvent = event.eventId || event.service.id === ServiceConstants.INTERNAL_EVENTS_AS_SERVICE_ID;
    const duration = moment(event.endRoundToNearest15Min).utc().diff(moment(event.start).utc(), "minutes");
    const numberOfSlots = duration / 15;
    const commonView = !isOpenSlots && (
      <span className={`patient-care-calendar-event-title ${isEventCancelled ? "-cancelled" : ""}`}>
        {event.type === 1 ? <Icon type="inline" size="smaller" icon={<IconVideo outline={false} />} /> : null}
        {event.type === 2 ? <Icon type="inline" size="smaller" icon={<IconPhone outline={false} />} /> : null}
        {`${isEventCancelled ? "Cancelled: " : ""}${isInternalEvent ? "Event: " : ""}${title}`}
      </span>
    );
    const monthView = (
      <div className="patient-care-calendar-event-content-wrapper cancelled">
        <div className="patient-care-calendar-event-content">
          {commonView}
          {!isOpenSlots && (
            <span>{event.startTime && event.startTime.split("T")[1].slice(0, 5)}</span>
          )}
        </div>
      </div>
    );
    const notMonthView = (
      <div className="patient-care-calendar-event-content-wrapper cancelled">
        {!isOpenSlots && numberOfSlots >= 3 && (
          <div className="patient-care-calendar-event-content">
            {`${moment(event.start).format("HH:mm")} - ${moment(event.end).format("HH:mm")}`}
          </div>
        )}
        <div className="patient-care-calendar-event-content">
          {commonView}
        </div>
        {!isOpenSlots && !isInternalEvent && (
          <span className="patient-care-calendar-event-fullname">
            {event.appointmentId ? `${event.patient && event.patient.firstName} ${event.patient && event.patient.lastName}` : ''}
          </span>
        )}
      </div>
    );
    return (currentView === AppointmentConstants.CALENDAR_VIEWS.MONTH ? monthView : notMonthView);
  };

  eventWrapper = ({ children, event }) => {
    const { isOpenSlots, showSelectedSlot } = this.props;
    return (
      <CustomView
        elements={children}
        zIndex={event.zIndex}
        isOpenSlots={isOpenSlots}
        showSelectedSlot={showSelectedSlot}
      />
    )
  };

  onSelectSlot = (slotInfo) => {
    const { action, start } = slotInfo;
    const { currentView, isOpenSlots, setDate, setPeriod, getSlots, branchId } = this.props;
    const monthViewAvailability = isOpenSlots && currentView === AppointmentConstants.CALENDAR_VIEWS.MONTH;
    if (!monthViewAvailability || action !== 'click') return;
    const date = getNextDate(
      AppointmentConstants.CHANGE_DATE_ACTIONS.NEXT,
      AppointmentConstants.CALENDAR_VIEWS.DAY,
      moment.utc(start).toDate()
    );
    setPeriod(AppointmentConstants.CALENDAR_VIEWS.DAY);
    setDate(moment(date).toDate());
    getSlots(branchId);
  };

  render() {
    const {
      isActiveAgendaDetails,
      resourceMap,
      currentView,
      currentDate,
      isPendingAppointments,
      isOpenSlots,
      isActiveEventDetails,
      isDisplayListView
    } = this.props;
    const { filteredEvents } = this.state;

    let components = {
      toolbar: CustomToolbar,
      event: this.customEvent,
      resourceHeader: CustomHeader,
    };
    if (currentView !== AppointmentConstants.CALENDAR_VIEWS.MONTH ) {
      components = {
        ...components,
        eventWrapper: this.eventWrapper
      }
    }

    let formats = {};
    if (!isOpenSlots) {
      formats.eventTimeRangeFormat = () => "";
    }

    const monthViewAvailability = isOpenSlots && currentView === AppointmentConstants.CALENDAR_VIEWS.MONTH;
    const events = monthViewAvailability ? [] : filteredEvents;

    return (
      <>
        {isDisplayListView && (
          <CalendarListView pendingAlertPage={PENDING_ALERT_PAGE.CALENDAR} />
        )}
        <div className="patient-care-calendar-wrap">
          <div className="patient-care-calendar">
            {isPendingAppointments && (
              <div className="patient-care-calendar-skeleton-wrap">
                <Loader size="small" type="inline" />
              </div>
            )}
            {!isDisplayListView && (
              <BigCalendar
                components={components}
                eventPropGetter={this.eventStyleGetter}
                slotPropGetter={this.slotPropGetter}
                dayPropGetter={
                  currentView === AppointmentConstants.CALENDAR_VIEWS.MONTH
                    ? this.slotPropGetter
                    : () => {}
                }
                tooltipAccessor="tooltip"
                localizer={localizer}
                date={currentDate}
                view={currentView}
                scrollToTime={new Date(0, 0, 0, 8, 0, 0)}
                views={[
                  AppointmentConstants.CALENDAR_VIEWS.DAY,
                  AppointmentConstants.CALENDAR_VIEWS.WEEK,
                  AppointmentConstants.CALENDAR_VIEWS.MONTH,
                ]}
                step={5}
                timeslots={12}
                events={events}
                endAccessor={(event: Object) =>
                  event.endRoundToNearest15Min || event.end
                }
                onSelectEvent={this.onSelectEvent}
                onView={this.handleChangeView}
                onNavigate={this.handleNavigate}
                popup={false}
                resources={
                  currentView === AppointmentConstants.CALENDAR_VIEWS.DAY &&
                  resourceMap &&
                  resourceMap.length > 1
                    ? resourceMap
                    : null
                }
                resourceIdAccessor="resourceId"
                resourceTitleAccessor="resourceTitle"
                className={`
                    ${
                      currentView === AppointmentConstants.CALENDAR_VIEWS.WEEK
                        ? "patient-care-week-view"
                        : ""
                    }
                    ${
                      currentView === AppointmentConstants.CALENDAR_VIEWS.DAY
                        ? "patient-care-day-view"
                        : ""
                    }
                  `}
                selectable={monthViewAvailability}
                onSelectSlot={this.onSelectSlot}
                formats={formats}
              />
            )}
          </div>
          {isActiveAgendaDetails && (
            <Modal>
              <AppointmentDetails
                type={AppointmentDetailsConstants.APPOINTMENT_DETAILS_TYPES.AGENDA}
              />
            </Modal>
          )}
          {isActiveEventDetails && (
            <Modal>
              <InternalEventDetails></InternalEventDetails>
            </Modal>
          )}
        </div>
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Calendar);
