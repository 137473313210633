import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { LinkIcon, IconChevronDown, IconChevronUp } from "@patient-access/ui-kit";

import * as RolesConstants from 'constants/RolesConstants';
import CalendarDropdown from './../CalendarDropdown/CalendarDropdown';

import { getBranchCalendarsList } from "actions/branchDetails";
import { closeSearch, closeSearchDetails, closeSlots, closeAgenda, closeBooking, closeInternalEvent } from 'actions/panel';
import { setActiveAdminMenu, setActiveReportsMenu } from 'actions/profile';
import { setBranchHeader, setOrganisationHeader } from "actions/router";
import { setBranchName, setBranchId, setOrganisationId } from "actions/calendarView";
import { openCalendarDropdown, closeCalendarDropdown, setDropdownOrganisation, setDropdownBranch } from "actions/calendarDropdown";
import type { Organisation, Branch } from "types/organisation";
import type { Action } from "types/actions";
import { ORGANISATION_TYPE } from 'constants/OrganisationsConstants';
import { setDisplayPendingAppointmentList } from "actions/branches";

import * as RoutesConstants from 'constants/RoutesConstants';

import locale from 'service/locale';
import './styles.scss';

type State = {
  adminUrl: string,
};

type Props = {
  isActiveAdmin: boolean,
  isActiveReports: boolean,
  currentRole: any,
  closeSearch: () => Action,
  closeSearchDetails: () => Action,
  setActiveAdminMenu: (status: boolean) => Action,
  setActiveReportsMenu: (status: boolean) => Action,
  closeSlots: () => Action,
  adminUrl: string,
  branchName: string,
  profileOrganisation: Organisation,
  setOrganisationHeader: (organisationId: string) => Action,
  setBranchHeader: (branchId: string) => Action,
  setBranchName: (branchName: string) => Action,
  setOrganisationId: (organisationId: string) => Action,
  setBranchId: (branchId: string) => Action,
  selectedBranch: Branch,
  isCalendarLinkOpened: boolean,
  openCalendarDropdown: () => Action,
  closeCalendarDropdown: () => Action,
  calendarView: any,
  setDropdownOrganisation: (organisation: Organisation) => Action,
  setDropdownBranch: (branch: Branch) => Action,
  isActiveAgenda: boolean,
  closeAgenda: () => Action,
  closeInternalEvent: () => Action,
  closeBooking: () => Action,
  selectedOrganisationId: string,
  getBranchCalendarsList: (organisationId: string, branchId: string, includeCustom: boolean) => any,
  setDisplayPendingAppointmentList: (isDisplayPendingAppointmentList: boolean) => Function
};

const mapStateToProps = (state) => ({
  adminUrl: state.profile.adminUrl,
  currentRole: state.roles.profileCurrentRole,
  branchName: state.calendarView.branchName,
  isActiveAdmin: state.profile.isActiveAdmin,
  isActiveReports: state.profile.isActiveReports,
  profileOrganisation: state.profile.organisation,
  selectedBranch: state.calendarDropdown.selectedBranch,
  isCalendarLinkOpened: state.calendarDropdown.isOpened,
  calendarView: state.calendarView,
  isActiveAgenda: state.panel.isActiveAgenda,
  selectedOrganisationId: state.calendarView.organisationId
});

const mapDispatchToProps = (dispatch: (action: any) => any): any => ({
  closeSearch: () => dispatch(closeSearch()),
  closeSearchDetails: () => dispatch(closeSearchDetails()),
  closeSlots: () => dispatch(closeSlots()),
  setActiveAdminMenu: (status) => dispatch(setActiveAdminMenu(status)),
  setActiveReportsMenu: (status) => dispatch(setActiveReportsMenu(status)),
  setOrganisationHeader: (organisationId) => dispatch(setOrganisationHeader(organisationId)),
  setBranchHeader: (branchId) => dispatch(setBranchHeader(branchId)),
  setBranchName: (branchName) => dispatch(setBranchName(branchName)),
  setOrganisationId: (organisationId) => dispatch(setOrganisationId(organisationId)),
  setBranchId: (branchId) => dispatch(setBranchId(branchId)),
  openCalendarDropdown: () => dispatch(openCalendarDropdown()),
  closeCalendarDropdown: () => dispatch(closeCalendarDropdown()),
  setDropdownOrganisation: (organisation) => dispatch(setDropdownOrganisation(organisation)),
  setDropdownBranch: (branch) => dispatch(setDropdownBranch(branch)),
  closeAgenda: () => dispatch(closeAgenda()),
  closeInternalEvent: ()=> dispatch(closeInternalEvent()),
  closeBooking: () => dispatch(closeBooking()),
  getBranchCalendarsList: (organisationId, branchId, includeCustom) => dispatch(getBranchCalendarsList(organisationId, branchId, includeCustom)),
  setDisplayPendingAppointmentList: (isDisplayPendingAppointmentList: boolean) => dispatch(setDisplayPendingAppointmentList(isDisplayPendingAppointmentList))
});

class MainNavigation extends Component<Props, State> {

  state = {
    isActiveAdmin: ![RolesConstants.BRANCH_MEMBER, RolesConstants.BRANCH_ADMIN].includes(this.props.currentRole.role),
    adminUrl: this.props.adminUrl || RoutesConstants.ADMIN_ORGANISATIONS,
  }

  componentWillReceiveProps = (nextProps: Props) => {
    const {
      currentRole,
      setActiveAdminMenu,
      profileOrganisation,
      setBranchName,
      setDropdownOrganisation,
      setDropdownBranch,
      isActiveAgenda,
      closeAgenda,
      setOrganisationId,
      setBranchId,
      getBranchCalendarsList
    } = nextProps;

    if (currentRole.organizationId !== this.props.currentRole.organizationId || currentRole.branchId !== this.props.currentRole.branchId) {
      setActiveAdminMenu((![RolesConstants.BRANCH_MEMBER, RolesConstants.BRANCH_ADMIN].includes(currentRole.role) ||
        currentRole.organisationType === ORGANISATION_TYPE.AFFILIATE));
      if (
        isActiveAgenda &&
        ((currentRole.role === RolesConstants.ADMIN || currentRole.role === RolesConstants.ORGANIZATION_ADMIN) ||
          currentRole.organisationType === ORGANISATION_TYPE.AFFILIATE)
      ) {
        closeAgenda();
      }
    }

    if (
      profileOrganisation.organisationId !== this.props.profileOrganisation.organisationId &&
      currentRole.role === RolesConstants.ORGANIZATION_ADMIN &&
      profileOrganisation.branches.length === 1
    ) {
      const includeCustom = true;
      setDropdownOrganisation(profileOrganisation);
      setDropdownBranch(profileOrganisation.branches[0]);
      setBranchName(profileOrganisation.branches[0].name);
      setOrganisationId(profileOrganisation.organisationId);
      setBranchId(profileOrganisation.branches[0].id);
      getBranchCalendarsList(profileOrganisation.organisationId, profileOrganisation.branches[0].id, includeCustom);
    }
  }

  handleActivateCalendar = (e: Event) => {
    const {
      closeSearch,
      closeSearchDetails,
      closeSlots,
      currentRole,
      setActiveAdminMenu,
      setActiveReportsMenu,
      profileOrganisation,
      setBranchHeader,
      setOrganisationHeader,
      selectedOrganisationId,
      setBranchName,
      setBranchId,
      setOrganisationId,
      selectedBranch,
      isCalendarLinkOpened,
      openCalendarDropdown,
      closeCalendarDropdown,
      closeBooking,
      closeInternalEvent,
      setDisplayPendingAppointmentList
    } = this.props;
    const isAdmin = [RolesConstants.ADMIN, RolesConstants.ORGANIZATION_ADMIN].includes(currentRole.role);
    const hasSingleBranch = (currentRole.role === RolesConstants.ORGANIZATION_ADMIN) && (profileOrganisation.branches && profileOrganisation.branches.length === 1);
    const userBranchId = hasSingleBranch
      ? profileOrganisation.branches[0].id
      : [RolesConstants.BRANCH_MEMBER, RolesConstants.BRANCH_ADMIN].includes(currentRole.role)
        ? currentRole.branchId
        : null;

    if (!selectedBranch.id && !hasSingleBranch && isAdmin) {
      e && e.preventDefault();
      isCalendarLinkOpened ? closeCalendarDropdown() : openCalendarDropdown();
      return;
    } else {
      setOrganisationHeader(selectedOrganisationId);
      setBranchHeader(selectedBranch.id || userBranchId);
    }

    if (hasSingleBranch) {
      const branch = profileOrganisation.branches[0];
      setOrganisationHeader(profileOrganisation.organisationId);
      setBranchHeader(branch.id);
      setBranchName(branch.name);
      setOrganisationId(profileOrganisation.organisationId);
      setBranchId(branch.id);
    }

    closeCalendarDropdown();
    closeSearchDetails();
    closeSearch();
    closeSlots();
    closeBooking();
    closeInternalEvent();
    setActiveAdminMenu(false);
    setActiveReportsMenu(false);
    setDisplayPendingAppointmentList(false);
  }

  handleActivateAdmin = () => {
    const { setActiveAdminMenu, setActiveReportsMenu, closeCalendarDropdown, setDisplayPendingAppointmentList } = this.props;
    closeCalendarDropdown();
    setActiveAdminMenu(true);
    setActiveReportsMenu(false);
    setDisplayPendingAppointmentList(false);
  }

  handleActivateReports = () => {
    const { setActiveAdminMenu, setActiveReportsMenu, closeCalendarDropdown, setDisplayPendingAppointmentList } = this.props;
    closeCalendarDropdown();
    setActiveAdminMenu(false);
    setActiveReportsMenu(true);
    setDisplayPendingAppointmentList(false);
  }

  handleNavigateToCalendar = () => {
    const { setActiveAdminMenu, setActiveReportsMenu, closeCalendarDropdown } = this.props;
    closeCalendarDropdown();
    setActiveAdminMenu(false);
    setActiveReportsMenu(false);
  }

  handleToggleCalendarDropdown = (e: Event) => {
    e && e.preventDefault();
    const { isCalendarLinkOpened, closeCalendarDropdown, openCalendarDropdown } = this.props;
    isCalendarLinkOpened ? closeCalendarDropdown() : openCalendarDropdown()
  }

  render() {
    const { adminUrl, currentRole, isActiveAdmin, isActiveReports, profileOrganisation, isCalendarLinkOpened, selectedBranch, calendarView } = this.props;
    const hasSingleBranch = (currentRole.role === RolesConstants.ORGANIZATION_ADMIN) && (profileOrganisation.branches && profileOrganisation.branches.length === 1);
    const isIconVisible = !isActiveAdmin && !isActiveReports && ((currentRole.role === RolesConstants.ADMIN) || ((currentRole.role === RolesConstants.ORGANIZATION_ADMIN) && !hasSingleBranch));
    const userOrganisationId = currentRole.role !== RolesConstants.ADMIN ? currentRole.organizationId : null;
    const userBranchId = hasSingleBranch
      ? profileOrganisation.branches[0].id
      : [RolesConstants.BRANCH_MEMBER, RolesConstants.BRANCH_ADMIN].includes(currentRole.role)
        ? currentRole.branchId
        : null;
    const canSeeCalendarMenu = !(currentRole.organisationType === ORGANISATION_TYPE.AFFILIATE);
    const canSeeReportMenu = RolesConstants.BRANCH_MEMBER !== currentRole.role;

    return (
      <nav className="patient-care-nav">
        <ul className="patient-care-nav-list patient-care-unstyled-list">
          {canSeeCalendarMenu &&
            (<li className={`
                patient-care-nav-item has-dropdown
                ${(!isActiveAdmin && !isActiveReports) || isCalendarLinkOpened ? "active" : ""}
                ${isCalendarLinkOpened ? "active-calendar" : ""}
              `}>
              <Link
                onClick={isIconVisible ? this.handleToggleCalendarDropdown : this.handleActivateCalendar}
                className={`patient-care-nav-link ${isIconVisible ? "has-dropdown" : ""}`}
                to={RoutesConstants.CALENDAR}>
                {selectedBranch.name || calendarView.branchName
                  ? (
                    <div className="patient-care-link-name">
                      {`${locale.Header.navigation.calendar}: ${selectedBranch.name || calendarView.branchName}`}
                    </div>
                  ) : (
                    <span className="patient-care-link-name">
                      {locale.Header.navigation.calendar}
                    </span>
                  )
                }
              </Link>
              {
                isIconVisible &&
                <div className="patient-care-dropdown-opener">
                  <LinkIcon
                    to={RoutesConstants.HOME}
                    size="small"
                    icon={isCalendarLinkOpened ? <IconChevronUp outline={true} /> : <IconChevronDown outline={true} />}
                    accessibilitySpan={locale.Header.calendarDropdownIcon}
                    onClick={this.handleToggleCalendarDropdown}
                  />
                </div>
              }
              {isCalendarLinkOpened &&
                <CalendarDropdown
                  userOrganisationId={userOrganisationId}
                  userBranchId={userBranchId}
                  handleNavigateToCalendar={this.handleNavigateToCalendar}
                />}
            </li>
            )}
          {canSeeReportMenu &&
          (<li className={`patient-care-nav-item ${isActiveReports ? 'active' : ''}`}>
            <span className="patient-care-link-name">
              <Link className="patient-care-nav-link" to={`${RoutesConstants.REPORTS}/${RoutesConstants.BASIC}`} onClick={this.handleActivateReports}>
                {locale.Header.navigation.reports}
              </Link>
            </span>
          </li>
          )}
          <li className={`patient-care-nav-item ${(isActiveAdmin || !canSeeCalendarMenu) && !isCalendarLinkOpened ? "active" : ""}`}>
            <span className="patient-care-link-name">
              <Link
                onClick={this.handleActivateAdmin}
                className="patient-care-nav-link"
                to={adminUrl}>
                {locale.Header.navigation.admin}
              </Link>
            </span>
          </li>
        </ul>
      </nav>
      )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainNavigation);
