import React, { Component } from "react";
import { getAppointmentDetails, completeBulkAppointment } from "actions/appointmentDetails";
import { connect } from "react-redux";
import { TableCalendar } from "components/Pages/Admin/AdminContent/CalendarsList/TableCalendar/TableCalendar";
import locale from 'service/locale';
import values from "lodash.values";
import type { AppointmentDetailsType } from "constants/AppointmentDetailsConstants";
import { setDisplayPendingAppointmentList } from "actions/branches";
import { LinkIcon, IconClose } from '@patient-access/ui-kit';

const allStatusesOptions = values(locale.Appointment.status);
const ouctcomeStatus = values(locale.Appointment.outcomeStatus);

type Props = {
    calendarList: any[],
    pendingAlertPage: String,
    handleAppointmentStatusChange: (value: any) => any,
    getAppointmentDetails: (appointmentId: string, type: AppointmentDetailsType, pendingAlertPage: string) => Function,
    completeBulkAppointment: (completeBulkAppointmentData: any, appointmentStatus: string, appointmentCount: number) => Action,
    setDisplayPendingAppointmentList: (isDisplayPendingAppointmentList: boolean) => Function
};

const mapStateToProps = (state) => ({
    calendarList: state.calendarView.calendarList
});

const mapDispatchToProps = (dispatch: any): any => ({
    getAppointmentDetails: (appointmentId, type, pendingAlertPage) => dispatch(getAppointmentDetails(appointmentId, type, pendingAlertPage)),
    completeBulkAppointment: (completeBulkAppointmentData, appointmentStatus, appointmentCount) => dispatch(completeBulkAppointment(completeBulkAppointmentData, appointmentStatus, appointmentCount)),
    setDisplayPendingAppointmentList: (isDisplayPendingAppointmentList: boolean) => dispatch(setDisplayPendingAppointmentList(isDisplayPendingAppointmentList))
});

const columns = [
    {
        Header: locale.CalendarListHeader.table.headers.time,
        accessor: 'time',
    },
    {
        Header: locale.CalendarListHeader.table.headers.status,
        accessor: 'status',
        Cell: ({ value }) => <span style={{ color: 'red' }}>{value}</span>
    },
    {
        Header: locale.CalendarListHeader.table.headers.serviceName,
        accessor: 'serviceName',
    },
    {
        Header: locale.CalendarListHeader.table.headers.customerName,
        accessor: 'customerName',
    }
];

class CalendarListView extends Component<Props, State> {

    disableListView = () => {
        const { setDisplayPendingAppointmentList } = this.props;
        setDisplayPendingAppointmentList(false);
    }

    render() {
        const { calendarList, pendingAlertPage } = this.props;

        let currentStatusesOptions = allStatusesOptions;
        currentStatusesOptions = [
            locale.Appointment.status.provided,
            locale.Appointment.status.notProvided,
            locale.Appointment.status.missed
        ];

        const isEmptyData = calendarList.length === 0;
        if (isEmptyData) {
          return (
            <div className="empty-data-info">
              <div className="no-appointments-close">
                <LinkIcon
                  to="#close"
                  size="small"
                  icon={<IconClose outline />}
                  onClick={this.disableListView}
                />
              </div>
              <span>{locale.CalendarListHeader.noResults.noCalendarsFilter}</span>
            </div>
          );
        }
        return (
            <div>
                <TableCalendar
                    data={calendarList}
                    columns={columns}
                    handleCheckboxClick={this.handleCheckboxClick}
                    getAppointmentDetails={this.props.getAppointmentDetails}
                    completeBulkAppointment={this.props.completeBulkAppointment}
                    disableListView={this.disableListView}
                    ouctcomeStatus={ouctcomeStatus}
                    pendingAlertPage={pendingAlertPage}
                    currentStatusesOptions={currentStatusesOptions}
                />
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CalendarListView);